.rect-auto {
  clip: rect(auto, auto, auto, auto);
}

.pie {
  position: absolute;
  border: 2px solid #379b21;
  width: 25px;
  height: 25px;
  clip: rect(0, 15px, 30px, 0);
  border-radius: 50%;
  transform: rotate(0deg);
}

.pie-fill {
  transform: rotate(180deg);
}

.c100 {
  *,
  *:before,
  *:after {
    box-sizing: content-box;
  }
  position: relative;
  font-size: 30px;
  width: 29px;
  height: 29px;
  text-align: left;
  border-radius: 50%;
  display: inline-block;
  background-color: #cccccc;

  &.center {
    float: none;
    margin: 0 auto;
  }

  >span {
    position: absolute;
    z-index: 1;
    width: 29px;
    line-height: 29px;
    font-size: 12px;
    color: #379b21;
    text-align: center;
    white-space: nowrap;
    transition: all 0.2s ease-out;
  }

  &:after {
    position: absolute;
    top: 2px;
    left: 2px;
    display: block;
    content: " ";
    border-radius: 50%;
    background-color: #ffffff;
    width: 25px;
    height: 25px;
    transition: all 0.2s ease-out;
  }

  .slice {
    position: absolute;
    width: 30px;
    height: 30px;
    clip: rect(0, 29px, 29px, 15px);
  }

  .bar {
    @extend .pie;
  }

  @for $i from 51 through 100 {
    &.p#{$i} {
      & .slice {
        @extend .rect-auto;
      }
      &.bar:after {
        @extend .pie-fill;
      }
      & .fill {
        @extend .pie;
        @extend .pie-fill;
      }
    }
  }

  @for $i from 1 through 100 {
    &.p#{$i} .bar {
      $degs: (360/100 * $i);
      transform: rotate(#{$degs}deg);
    }
  }
}