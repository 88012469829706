//============ Rules style start ===========

.charge-backs ul,
.minor-travel ul {
  padding-left: 30px;
  margin-bottom: 15px;
}

.b {

  &-tabs {

    &-nav {
      margin-top: -15px;
      margin-bottom: 20px;
      border-left: 1px solid #adb6bd;

      &:after {
        clear: both;
      }

      &:after,
      &:before {
        content: " ";
        display: table;
      }

      &__item {
        position: relative;
        padding: 25px 90px 25px 30px;
        width: 33.33333%;
        height: 111px;
        display: block;
        float: left;
        border-right: 1px solid #adb6bd;
        border-bottom: 1px solid #adb6bd;
        color: #1b3d6a;
        font-size: 22px;
        text-decoration: none;
        cursor: pointer;

        .lang-es & {
          padding-right: 70px;
        }

        &:after {
          position: absolute;
          top: 50%;
          right: 30px;
          margin-top: -17px;
          width: 38px;
          height: 34px;
          display: block;
          content: " ";
          background: url("../../img/button-sprite.png") -96px -10px;
        }

        &.active {
          border-bottom: 0;

          &:after {
            background: url("../../img/button-sprite.png") -8px -10px;
          }

          .hardcopy-mode & {
            border-bottom: 1px solid #adb6bd;

            &:after {
              background: url("../../img/button-sprite.png") -96px -10px;
            }
          }
        }
      }
    }

    &__item {
      padding: 20px 0;
      display: none;
    }
  }
}

.list-number {
    list-style-type: decimal;
    font-weight: bold;
    
    h4 {
        display: inline-block;
    }
}

.list-alpha {
    margin-left: 30px;
    list-style-type: lower-alpha;
    font-weight: normal;
}

.rules-title {
    margin: 15px 0;
    text-transform: uppercase;
}

.rules-text {
    margin-bottom: 15px;
    font-weight: normal;
    text-align: justify;
    text-indent: 30px;

    a {
        cursor: pointer;
    }

  &-disclaimer {
    text-align: justify;
  }

  &.no-margin-bottom {
    margin-bottom: 0;
  }
}

.btn_close {
    position: relative;
    padding: 15px 40px 15px 0;
    box-shadow: none;
    color: #adb6bd;
    font-weight: normal;
    text-align: left;

    &:after {
        position: absolute;
        top: 8px;
        left: 65px;
        width: 38px;
        height: 34px;
        content: "";
        background: url("../../img/button-sprite.png") -142px -10px;;
    }
}

.baggage-table-wrapper {
    margin: 10px 0;
    height: 100%;
    display: table;
    font-size: 0.84em;

    &:after {
        clear: both;
    }

    &:after,
    &:before {
        content: " ";
        display: table;
    }
}

.baggage-table-wrapper-tr {
    display: table-row;
}

.baggage-table-wrapper-td {
    display: table-cell;
    vertical-align: top;

    .left {
        width: 300px;

        .baggage-table {
            width: 100%;
        }
    }

    .right {
        width: 500px;
    }
}

.baggage-table {
    height: 100%;
    border: 1px solid #cacaca;
    border-collapse: collapse;

    td {
        padding: 5px 20px;
        border: 1px solid #cacaca;
        vertical-align: middle;
    }
}

.baggage-table_checked-bag {
    margin-left: -1px;
}

.baggage-table-header {
    background: #e8e8e9;

    tr {
        height: 30px;
    }
}

.fixed-td {
    width: 90px;
}

.cell-divider {
    margin: 5px 0;
    border-bottom: 1px dashed #ddd6d9;
}

.baggage {
    margin-bottom: 10px;
    padding: 10px;
    word-wrap: break-word;
    background: #f7f7f7;
}

.hide-cell {
    display: none;
}

.b-wrapper_full {
    padding-left: 0;
    padding-right: 0;
}

@include tablet {

  rules .b-container_line {
    border-top: 0;
  }

  .b-tabs {

    &-nav {
      margin-top: -15px;
      margin-bottom: 50px;

      &:after {
        clear: both;
      }

      &:after,
      &:before {
        display: table;
        content: " ";
      }

      &__item {
        width: 100%;
        height: auto;
        border-top: 1px solid #adb6bd;
        border-bottom: 0;
        border-right: 0;
        border-left: 0;
        float: none;
        cursor: pointer;

        &:first-of-type {
          border-left: 0;
        }

        &:after {
          position: absolute;
          top: 50%;
          right: 30px;
          margin-top: -17px;
          width: 38px;
          height: 34px;
          display: block;
          content: " ";
          background: url("../../img/button-sprite.png") -96px -10px;
        }
      }
    }

    &__item {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .b-wrapper_full {
    padding: 0;
  }
}

@include tablet-small {

    .baggage-table-wrapper {
        width: 100%;
        display: table;
    }

    .baggage-table-wrapper-tr {
        display: block;
    }

    .baggage-table-wrapper-td {
        display: block;

        .left,
        .right {
            width: 100%;
        }
    }

    .baggage-table {
        margin: 10px 0;
        width: 100%;

        td {
            padding: 5px 10px;
        }
    }

    .baggage-table_checked-bag {
        margin-left: 0;
    }

    .hide-cell {
        display: table-cell;
    }
}

//============ Rules style end ===========
