//============ Header style start ===========

.b-container_header {
    background: #0c1f34 url("../../img/header-bg.jpg") center no-repeat;
}

.b-container_pre-header {
    color: #fff;
}

.header-logo {
    padding: 10px 0;
}

.header-phone-block {
    position: relative;
    padding: 5px 20px 5px 35px;
    border-radius: 15px;
    display: inline-block;
    color: #fff;
    font-weight: bold;
    text-decoration: none;
    background: #389b1e;
    cursor: pointer;
}

.header-phone {
    text-decoration: none;
    color: #fff;
}

.ico-phone {
    position: absolute;
    top: 7px;
    left: 15px;
    width: 11px;
    height: 14px;
    display: inline-block;
    background-position: -5px -5px;
    cursor: pointer;
}

.traveler-name {
    padding-left: 20px;
}

.confirm-title {
    padding-top: 13px;
    color: #fff;
    font-family: "Bebas", sans-serif;
    font-size: 2.4em;
    text-transform: uppercase;
    text-align: center;
}

.square {
    padding: 0 20px;
    font-size: inherit;
}

.square_blue {
    color: #2e74ba;
}

.square_yellow {
    color: #ebba51;
}

.square_no-indent {
    padding: 0;
}

.link_white {
    color: #fff;
}

.language-selector {
    margin-left: 30px;
    width: 50px;
    height: 26px;
    border-radius: 2px;
    background-color: #ffffff;
}

@include tablet {

  .confirm-title {
    margin: 10px 0;
    text-align: center;
  }

  .traveler {

    &-name {
      padding-left: 5px;
    }

    &-email {
      display: block;
    }

    &-email, &-phone {
      margin: 10px 0;
    }
  }

  .language-selector {
    margin: 0;
    width: 50px;
  }
}

//============ Header style end ===========
