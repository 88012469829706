//============ Submit style start ===========

.save-purchase {
    padding: 10px;
    width: 300px;
    display: inline-block;
    color: #379d1d;
    font-size: 1.7em;
    line-height: 26px;
}

.tick-ico {
    margin-left: 20px;
    width: 28px;
    height: 19px;
    display: inline-block;
    background-position: -343px -10px;
}

.optimus {
    padding: 10px 20px;
    display: inline-block;
}

.btn_secure {

  .lang-es & {
    min-width: 320px;
  }

  .affirm-selected & {
    display: none;
  }

  &:before {
    position: absolute;
    top: 10px;
    left: calc(50% - 125px);
    width: 18px;
    height: 23px;
    content: "";
    background: url("../../img/icon-sprite.png") -305px -5px;

    .lang-es & {
      left: calc(50% - 145px);
    }
  }
}

.unexpected-error {
    margin: 0 auto;
    max-width: 600px;
    text-align: center;
}

//============ Submit style end ===========
