/*
 * These classes are made to overwrite any css from plugins or bootstrap or
 * write own common classes that are used more than once
 *
 */
//============ Validation start =========


.ng-invalid {

  &.input,
  &.select {

    &.ng-dirty {
      color: #cf0b16 !important;
      border-color: #e7222e !important;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.23) !important;
    }
  }
}


.ng-invalid-explicit,
.ng-invalid-explicit:focus,
.ng-invalid-explicit:focus:invalid:focus,
.form-validate.error input,
.form-validate.error textarea {
  color: #cf0b16 !important;
  border-color: #e7222e !important;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.23) !important;
}

.note-error {
  padding: 6px 10px;
  border: 1px solid #daa69c;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.23);
  background: #f7d4d3;
  line-height: 16px;
  font-size: 12px;
  text-shadow: 0 1px 1px #fff;
  color: #aa3b30;
  cursor: default;
  font-weight: normal;

  &.single {
    margin-top: 10px;
  }

  &-container {
    clear: both;

    &:first-of-type {
      margin-top: 10px;
    }
  }

  &.note-block,
  &.note-error.ng-note.note-block {
    margin: 5px 0;
    border-top: 1px solid #daa69c;
  }

  &.ng-hide {
    display: none;
  }
}

//============ Validation end =========