//============ Error page style start ===========

.info-phone-block {
    position: relative;
    width: 300px;
    margin: 18px auto;
    border-radius: 25px;
    background: #389b1e;
}

.info-phone {
    padding: 8px 35px 8px 10px;
    display: inline-block;
    font-size: 25px;
    color: #fff;
    font-weight: bold;
    text-decoration: none;
    vertical-align: middle;
}

.phone-ico {
    top: 10px;
    left: 25px;
    width: 24px;
    height: 24px;
    display: inline-block;
    background-position: -203px -5px;
    vertical-align: middle;
    margin-left: 15px;
}

.b-info-container_footer {
    position: relative;
    margin-top: 3px;
    padding: 30px 50px;

    &::after,
    &::before {
        position: absolute;
        top: -5px;
        width: 50%;
        height: 3px;
        display: block;
        content: " ";
        background: #000;
    }

    &::before {
        left: 0;
        background: #379d1d;
    }

    &::after {
        right: 0;
        background: #2f73bc;
    }
}

.cross-big {
    margin: 0 auto;
    width: 80px;
    height: 80px;
    background-position: -5px -5px;
}

@include tablet-small {
    .info-phone {
        margin: 10px 0;
    }
}

//============ Error page style end ===========
