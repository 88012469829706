//============ Loading style start ===========

.loading-container {
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  position: relative;
  background: url('../../img/loading-bg.jpg') no-repeat;
  background-size: cover;
  background-position: center;

  .line {
    width: 100%;
    height: 5px;

    &::before, &::after {
      width: 50%;
      height: 5px;
      display: block;
      content: "";
    }

    &::before {
      float: left;
      background: #379d1d;
    }

    &::after {
      float: right;
      background: #2f73bc;
    }
  }

  .loading-content {
    position: relative;
    transform: translateY(-50%);
    top: 50%;
    text-align: center;
  }

  .loading-text {
    font-size: 46px;
    color: #fff;
    margin: 50px 0;
    font-family: "Loading Sans";
    letter-spacing: 3px;
    padding-left: 20px;
  }

  .loading-gif {
    border-radius: 50%;
    border: 1px solid #007bc1;
  }
}

@include mobile {
  
  .loading-container {

    .loading-text {
      font-size: 32px;
    }
  }
}

@include tablet-small {
  .b-info-container_footer {
      padding: 30px 20px;
  }
}

//============ Loading style end ===========
