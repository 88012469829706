@font-face {
  font-family: 'Loading Sans';
  font-style: normal;
  font-weight: 100;
  src: url('../../fonts/OpenSans-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Bebas';
  font-style: normal;
  font-weight: 500;
  src: url('../../fonts/BebasNeue-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Bebas';
  font-style: normal;
  font-weight: 700;
  src: url('../../fonts/bold_webfont.eot') format('eot');
  src: url('../../fonts/bold_webfont.eot?#iefix') format('embedded-opentype'),
       url('../../fonts/bold_webfont.woff2') format('woff2'),
       url('../../fonts/bold_webfont.woff') format('woff'),
       url('../../fonts/bold_webfont.ttf') format('truetype'),
       url('../../fonts/bold_webfont.svg#bebas_neuebold') format('svg');
}
