//============ Common style start ===========

@-webkit-keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}


* {
    box-sizing: border-box;
}

html {
    overflow: initial !important;
}

body {
    margin: 0;
    min-width: 320px;
    color: #2c3e50;
    font: normal 14px / normal 'Open Sans', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}

p {
    margin: 0 0 5px;
}

ol,
ul {
    margin: 0;
    padding: 0;
    list-style: inside;
}

.valing-mid {
  vertical-align: middle;
}

.overflow-hidden {
  overflow: hidden !important;
  position: relative;
}

.display-block {
    display: block !important;
}

.hardcopy-highlight {
    background-color: #FFBABA !important;

    &:before {
        background-color: #FFBABA !important;
    }
}

.hardcopy-protect {
    padding-left: 0 !important;
    float: left !important;

    .itn-hardcopy-hide {
        display: none;
    }

    &.itn-protection-right-container {
        margin-top: 79px;
    }
}

.hardcopy-mode {

  * {
    pointer-events: none;
  }
}

.row {
    position: relative;
}

.vertical-margin {
    margin: 10px 0 !important;
}

.hidden-input {
  display: none;
}

.text {

  &-blue {
    color: #2e74ba;
  }

  &-discount {
    color: #991122;
  }

  &-upper {
    text-transform: uppercase;
  }

  &-underline {
    text-decoration: underline;
  }

  &-red {
    color: #ff4622;
  }

  &-bold {
    font-weight: bold;
  }

  &-right {
    text-align: right;
  }

  &-center {
    text-align: center;
  }

  &-small {
    font-size: 0.84em;
  }

  &-dashed {
    border-bottom: 1px dashed #94b4d4;
  }

  &-normal {
    font-weight: normal;
  }

  &-mutted {
    color: #767676;
  }
}

.pull-right {
    float: right;
}

.pull-left {
    float: left;
}

.collapse {
    display: none;
}

.bg-freeze {
    height: 100% !important;
    overflow: hidden !important;
}

.show-sm {
  display: none !important;

  &-inline {
    display: none !important;
  }
}

.inline-block {
    display: inline-block;
}

.invalid-checkbox {
    background-color: #ff4622;
}

.no-padding {
    padding: 0 !important;
}

.no-margin {
    margin: 0 !important;
}

.cursor-default {
    cursor: default !important;
}

.row:after,
.row:before {
    content: " ";
    display: table;
}

.row:after {
    clear: both;
}

.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9 {
    min-height: 1px;
    float: left;
}

.col-10:empty,
.col-11:empty,
.col-12:empty,
.col-1:empty,
.col-2:empty,
.col-3:empty,
.col-4:empty,
.col-5:empty,
.col-6:empty,
.col-7:empty,
.col-8:empty,
.col-9:empty {
    height: 1px;
}

.col-1 {
    width: 8.33333%;
}

.col-2 {
    width: 16.66666%;
}

.col-3 {
    width: 25%;
}

.col-4 {
    width: 33.33333%;
}

.col-5 {
    width: 41.66666%;
}

.col-6 {
    width: 50%;
}

.col-7 {
    width: 58.33333%;
}

.col-8 {
    width: 66.66666%;
}

.col-9 {
    width: 75%;
}

.col-10 {
    width: 83.33333%;
}

.col-11 {
    width: 91.66666%;
}

.col-12 {
    width: 100%;
    float: none;
    clear: both;
}

.block-title {
  position: relative;
  display: inline-block;
  text-transform: uppercase;
  color: #19365e;
  font-size: 19px;
  font-family: "Bebas", sans-serif;

  &.large {
    display: block;
    margin: 40px 0 30px 0;
    padding: 20px;
    font-size: 34px;
    color: #ffffff;
    background-color: #122D4E;
  }

  &.active,
  &.inactive {
    cursor: pointer;

    &:after {
      position: absolute;
      top: 50%;
      right: -50px;
      margin-top: -14px;
      width: 38px;
      height: 34px;
      display: block;
      content: " ";
      background: url("../../img/button-sprite.png") -54px -10px;
    }
  }

  &.active {

    &:after {
      background: url("../../img/button-sprite.png") -10px -10px;
    }
  }
}

.b-info,
.b-primary,
.b-question,
.b-warning {
    position: relative;
    padding-left: 15px;
}

.b-info:before,
.b-primary:before,
.b-question:before,
.b-warning:before {
    position: absolute;
    top: 3px;
    left: 0;
    width: 9px;
    height: 9px;
    content: "";
}

.b-warning:before {
    width: 9px;
    height: 9px;
    display: inline-block;
    background: url("../../img/icon-sprite.png") -141px -5px;
}

.b-info:before {
    width: 9px;
    height: 9px;
    display: inline-block;
    background: url("../../img/icon-sprite.png") -60px -5px;
}

.b-primary:before {
    width: 7px;
    height: 7px;
    display: inline-block;
    background: url("../../img/icon-sprite.png") -43px -5px;
}

.b-container {
  position: relative;
  width: 100%;
  background: #fff;

  &.protection {
    padding-top: 80px;
  }

  &:after,
  &:before {
    content: " ";
    display: table;
  }

  &:after {
    clear: both;
  }

  &_grey {
    margin: 0 auto;
    max-width: 945px;
    background: #f4f2f3;
  }

  &_dark-blue {
    color: #fff;
    background: #122d4e;
  }

  &_warning {
    margin: 0 auto;
    max-width: 945px;
    border-top: 1px solid #f6de97;
    border-bottom: 1px solid #f6de97;
    background: #faedc6;
  }

  &_error {
    border-bottom: 3px solid #ff4622;
  }

  &_line {
    margin: 0 auto;
    max-width: 945px;
    border-top: 1px solid #adb6bd;

    &.margin-top {
      margin-top: 40px;
    }
  }

  &_info {
    padding: 60px 0 120px;
    background: #f0f4f8;
  }

  &_lines {
    margin-top: 5px;

    &:before,
    &:after {
      position: absolute;
      top: -5px;
      width: 50%;
      height: 5px;
      display: block;
      background: #000;
      content: " ";
    }

    &:before {
      left: 0;
      background: #379d1d;
    }

    &:after {
      right: 0;
      background: #2f73bc;
    }
  }
}

.b-wrapper {
  position: relative;
  margin: 0 auto;
  padding: 15px;
  width: 100%;
  max-width: 975px;

  &:after,
  &:before {
    content: " ";
    display: table;
  }

  &:after {
    clear: both;
  }

  &_double-indent {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  &_small {
    max-width: 770px;
  }
}

.b-itn-number {
    padding: 0 8px;
    width: 293px;
    height: 52px;
    display: table;
    border-top: 2px solid #ebba51;
    border-bottom: 2px solid #ebba51;
    text-align: center;
    color: #ebba51;
    background: url("../../img/itn-number2.png") repeat-y center;
}

.b-itn-number-cell {
    padding-left: 10px;
    display: table-cell;
    vertical-align: middle;
    text-align: left;
    font-size: 13px;

    .lang-es & {
        font-size: 12px;
    }
}

.input-label {
    min-height: 17px;
    display: block;
    font-weight: bold;
    font-size: 0.84em;
    cursor: pointer;

    &:empty {
        height: 17px;
        display: block;
    }
}


.checkbox-label {
    position: relative;
    top: -2px;
    padding-left: 5px;
    display: inline;
    cursor: pointer;
    font-size: 12px;
}

.radio-label {
    position: relative;
    top: -1px;
    padding-left: 2px;
    padding-right: 10px;
    display: inline;
    color: #19365e;
    font-size: 13px;
    font-weight: 400;
    line-height: 22px;
    cursor: pointer;
}

.green-label {
    color: #249546;
}

.red-label {
    color: #ff0000;
}

.input {
  margin: 3px 0;
  padding: 13px;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #adb6bd;
  border-radius: 3px;

  &.disabled {
    background-color: #eeeeee;
  }

  &.ng-invalid-explicit {
      border: 1px solid #ff4622;
      box-shadow: 0 0 5px #ff4622;
  }
}

.select {
  appearance: none;
}

.note {
    display: block;
}

.input:focus {
    outline: none;
    box-shadow: 0 0 5px #51cbee !important;
    border-color: #51cbee !important;
}

.form-group {
    position: relative;
    margin: 8px 0;
    padding-right: 20px;
}

.b-tooltip {
    position: absolute;
    font-size: 0.84em;
    cursor: help;

    &-text {
      position: absolute;
      top: 30px;
      right: -55px;
      z-index: 200;
      display: none;
      padding: 10px;
      width: 280px;
      box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
      border-radius: 4px;
      color: #2c3e50;
      font-size: 0.92em;
      background: #f6eed6;

      &:before {
        position: absolute;
        left: 190px;
        top: -6px;
        border-style: solid;
        border-width: 0 5px 7px 5px;
        border-color: transparent transparent #f6eed6 transparent;
        content: " ";
      }
    }

    &:hover, &:active {

      & > .b-tooltip-text {
        display: block;
      }
    }
}

.custom-dropdown {
    position: relative;
    margin-right: 5px;
    margin-top: 3px;
    width: 100%;
    height: 44px;
    display: inline-block;
    border: 1px solid #adb6bd;
    border-radius: 3px;
    vertical-align: middle;

    &:before {
        position: absolute;
        top: 1px;
        right: 1px;
        width: 24px;
        height: 38px;
        content: " ";
        pointer-events: none;
    }

    &:after {
        position: absolute;
        top: 18px;
        right: 8px;
        width: 17px;
        height: 10px;
        content: " ";
        background: url("../../img/button-sprite.png") -318px -10px;
        pointer-events: none;
        color: rgba(0, 0, 0, 0.4);
    }

    select {
        margin: 0;
        padding: 0 0 0 13px;
        width: 100%;
        height: 42px;
        border: 1px solid transparent;
        border-radius: 3px;
        font-size: inherit;
        text-indent: 0.01px;
        cursor: pointer;
        background-color: #fff;
        -webkit-appearance: none;
        -moz-appearance: none;

        &:focus {
            outline: 0;
            box-shadow: 0 0 5px #51cbee;
            border: 1px solid #51cbee;
        }

        &[disabled] {
            color: rgba(0, 0, 0, 0.3);
            background: #eee;

            &::after {
                color: rgba(0, 0, 0, 0.1);
            }
        }

      &::-ms-expand {
        display: none;
      }
    }

    .active,
    &:active {
      background-color: #e4ebf0;
    }

    &.disabled::before {
        background: #eee;
    }
}

.btn {
    margin: 15px 0;
    padding: 15px 20px;
    display: inline-block;
    border-radius: 3px;
    border: 0;
    transition: background 0.3s linear;
    box-shadow: 1px 1px 1px #d5d5d5;
    font-weight: bold;
    font-size: 1.16em;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
}

.btn_green {
    position: relative;
    padding: 15px 60px;
    display: block;
    background: #379b21;
    color: #fff;

    &:disabled, &:disabled:hover {
        background: #70a264;
        color: #ececec;
    }
}

.btn_green:hover {
    background-color: #30861d;
}

.btn:hover {
    cursor: pointer;
}

.btn:focus {
    outline: none;
}

.link {
    color: #2e74ba;
}

.preloader {
    position: fixed;
    z-index: 10000;
    top: 0;
    width: 100%;
    height: 100%;
    display: none;
    background: rgba(255, 255, 255, 0.9);

    img {
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -80px 0 0 -50px;
    }
}

.overlay {
    position: fixed;
    top: 0;
    z-index: 999;
    width: 100%;
    height: 100%;
    display: none;
    background: rgba(0, 0, 0, 0.5);

    &.youtube-active {
        display: block;
        animation: fadeIn 0.7s;
    }
}

.b-info-container {
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.14);
    border-radius: 4px;
    font-size: 1.24em;
    background: #fff;
}

.b-info-container_content {
    padding: 35px;
}

.b-browser-title {
    margin-top: 20px;
    margin-bottom: 35px;
    line-height: 30px;
}

.clearfix {
    &::after,
    &::before {
        content: '';
        display: table;
        clear: both;
    }
}

.button-sprite {
    width: 26px;
    height: 26px;
    background: url("../../img/button-sprite.png") no-repeat;
    display: block;
}

.cross-ico {
    margin-top: 5px;
    width: 7px;
    height: 7px;
    background-position: -186px -10px;
}

.big-icon-sprite {
    display: block;
    background-image: url("../../img/big-icon-sprite.png");
}

.icon-sprite {
    display: block;
    background-image: url("../../img/icon-sprite.png");
}

.ico-question {
    display: inline-block;
    width: 7px;
    height: 7px;
    background-position: -26px -5px;
}

select option {
    color: black;
    background-color: white !important;

    &:disabled {
        color: #e0e0e0;
    }
}

.hidden {
  display: none !important;
}

.show-xs {
    display: none !important;
}

.hardcopy-mode {

    .hardcopy-hidden {
        display: none !important;
    }
}

@include tablet {
    .hide-sm {
        display: none !important;
    }

    .show-sm {
        display: block !important;

      &-inline {
        display: inline !important;
      }
    }

    .show-xs {
        display: block !important;
    }

    .hide-xs {
        display: none !important;
    }

    .row:after,
    .row:before {
        content: " ";
        display: table;
    }

    .row:after {
        clear: both;
    }

    .col-1-sm,
    .col-10-sm,
    .col-11-sm,
    .col-12-sm,
    .col-2-sm,
    .col-3-sm,
    .col-4-sm,
    .col-5-sm,
    .col-6-sm,
    .col-7-sm,
    .col-8-sm,
    .col-9-sm {
        float: left;
    }

    .col-1-sm:empty,
    .col-10-sm:empty,
    .col-11-sm:empty,
    .col-12-sm:empty,
    .col-2-sm:empty,
    .col-3-sm:empty,
    .col-4-sm:empty,
    .col-5-sm:empty,
    .col-6-sm:empty,
    .col-7-sm:empty,
    .col-8-sm:empty,
    .col-9-sm:empty {
        height: 1px;
    }

    .col-1-sm {
        width: 8.33333%;
    }

    .col-2-sm {
        width: 16.66666%;
    }

    .col-3-sm {
        width: 25%;
    }

    .col-4-sm {
        width: 33.33333%;
    }

    .col-5-sm {
        width: 41.66666%;
    }

    .col-6-sm {
        width: 50%;
    }

    .col-7-sm {
        width: 58.33333%;
    }

    .col-8-sm {
        width: 66.66666%;
    }

    .col-9-sm {
        width: 75%;
    }

    .col-10-sm {
        width: 83.33333%;
    }

    .col-11-sm {
        width: 91.66666%;
    }

    .col-12-sm {
        width: 100%;
        float: none;
        clear: both;
    }

    .col-12-sm:after,
    .col-12-sm:before {
        content: " ";
        display: table;
    }

    .col-12-sm:after {
        clear: both;
    }

    .text-center-sm {
        text-align: center;
    }

    .block-title {
        display: block;
        text-align: center;
    }

    .block-title.active:after,
    .block-title.inactive:after {
        right: 0;
    }

    .b-container_error,
    .b-container_warning {
        text-align: center;
    }

    .b-question,
    .b-warning {
        padding-left: 0;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .b-question:before,
    .b-warning:before {
        top: -33px;
        left: 50%;
        margin-left: -16px;
        width: 33px;
        height: 34px;
        content: "";
    }

    .b-warning:before {
        width: 33px;
        height: 34px;
        display: inline-block;
        background: url("../../img/icon-sprite.png") -160px -5px;
    }

    .b-itn-number {
        margin: 0 auto;
        float: none;
    }

    .header-phone {
        margin-top: 5px;
        margin-left: 10px;
    }

    .input {

      &:focus {
        box-shadow: 0 0 5px #51cbee inset;
      }

      &-label {
        text-align: center;

        &:empty {
          display: none;
        }

        &_min {
          position: absolute;
          padding: 0 0 0 12px;
          height: 43px;
          width: 120px;
          display: table;
          color: #a0a0a0;
          font-weight: normal;
          text-align: left;
          line-height: 43px;
          z-index: 1;

          &.intl {
            right: 5px;
            text-align: right;
          }

          span {
            display: table-cell;
            vertical-align: middle;
            line-height: 1;
          }
        }
      }
    }

    .form {

      &-group {
        margin: 0;
        padding-right: 0;

        &-mobile {
          margin: 10px 0;

          .input {
            margin: 0;
            padding-left: 130px;
            border-radius: 3px;
            border-top: 0;

            &-error {
              box-shadow: 0 0 5px #ff4622 inset;
            }
          }

          .form-group:first-of-type .input {
            border-top: 1px solid #adb6bd;
          }
        }
      }
    }

    .select-group-horizontal,
    .select-group-vertical {
      margin: 10px 0;
    }

    .select-group-horizontal .custom-dropdown {
        border-left: 0;
        border-radius: 3px;
    }

    .select-group-horizontal > .form-group:first-of-type .custom-dropdown {
        border-left: 1px solid #adb6bd;
    }

    .custom-dropdown {
        margin: 0;
    }

    .btn_green {
        margin-top: 15px;
        width: 100%;
        display: block;
    }

    .b-tooltip {
        position: relative;
        margin: 8px 0;
        text-align: center;
    }

    .b-tooltip-text {
        top: 35px;
        left: 50%;
        right: auto;
        margin-left: -140px;
    }

    .b-tooltip-text:before {
        top: -5px;
        left: 50%;
        border-style: solid;
        border-width: 0 5px 7px 5px;
        border-color: transparent transparent #f6eed6 transparent;
    }
}

@include tablet-small {
    .row:after,
    .row:before {
        content: " ";
        display: table;
    }

    .row:after {
        clear: both;
    }

    .col-1-xs,
    .col-10-xs,
    .col-11-xs,
    .col-12-xs,
    .col-2-xs,
    .col-3-xs,
    .col-4-xs,
    .col-5-xs,
    .col-6-xs,
    .col-7-xs,
    .col-8-xs,
    .col-9-xs {
        float: left;
    }

    .col-1-xs:empty,
    .col-10-xs:empty,
    .col-11-xs:empty,
    .col-2-xs:empty,
    .col-3-xs:empty,
    .col-4-xs:empty,
    .col-5-xs:empty,
    .col-6-xs:empty,
    .col-7-xs:empty,
    .col-8-xs:empty,
    .col-9-xs:empty,
    .col-12-xs:empty {
        height: 1px;
    }

    .col-1-xs {
        width: 8.33333%;
    }

    .col-2-xs {
        width: 16.66666%;
    }

    .col-3-xs {
        width: 25%;
    }

    .col-4-xs {
        width: 33.33333%;
    }

    .col-5-xs {
        width: 41.66666%;
    }

    .col-6-xs {
        width: 50%;
    }

    .col-7-xs {
        width: 58.33333%;
    }

    .col-8-xs {
        width: 66.66666%;
    }

    .col-9-xs {
        width: 75%;
    }

    .col-10-xs {
        width: 83.33333%;
    }

    .col-11-xs {
        width: 91.66666%;
    }

    .col-12-xs {
        width: 100%;
        float: none;
        clear: both;
    }

    .col-12-xs:after,
    .col-12-xs:before {
        content: " ";
        display: table;
    }

    .col-12-xs:after {
        clear: both;
    }

    .input-label_min {
        font-size: 14px;
    }

    .input {
        font-size: 14px;
    }

    .custom-dropdown select {
        padding-left: 11px;
    }

    .b-tooltip {
        font-size: 14px;
    }

    .b-container_info {
        padding: 20px 0;
    }

    .b-info-container {
        font-size: 1.16em;
    }

    .b-info-container_content {
        padding: 15px;
    }

    .b-browser-title {
        font-size: 1.16em;
    }
}

@include mobile-large {

    .block-title {
      margin-bottom: 8px;

      &.large {
        padding-top: 20px;
        font-size: 24px;
      }
    }
}

@include mobile {

  .hidden-xs {
    display: none !important;
  }
}

.invisible {
    visibility: hidden !important;
}

//============ Common style end ===========
