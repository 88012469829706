//============ Total style start ===========

.b-total {
    padding: 20px;
    box-shadow: 1px 1px 0 #d5d5d5;
    border-radius: 3px;
    background: #fff;
    font-size: 13px;

    &:after {
        clear: both;
    }

    &:after,
    &:before {
        content: " ";
        display: table;
    }
}

.b-total-passengers {
    margin-bottom: 30px;
}

.b-total_left {
    padding-right: 20px;
    border-right: 1px solid #d5d5d5;
}

.b-total_right {
    padding-left: 30px;
}

.border {
    border-left: 1px solid #d5d5d5;
}

.b-total-price {
    margin: 5px 0;
    color: #2e74ba;
    font-size: 2em;
    font-weight: bold;
}

.b-total-price-currency {
    position: relative;
    top: -10px;
    font-size: 0.5em;
    color: #2c3e50;
    text-transform: uppercase;
    text-align: left;
}

.currency-align {
    top: 0;
}

.card-disclaimer-uk {
    width: 66.66%;
    float: right;
    padding: 15px 0 0 30px;
}

@include tablet {

  .b-total_left {
    margin-bottom: 15px;
    padding-right: 0;
    padding-bottom: 20px;
    border-right: 0;
    border-bottom: 1px solid #d5d5d5;
  }

  .b-total-price {
    font-size: 3em;
  }

  .border {
      padding-left: 0;
      border: none;
  }

  .text-medium2-sm {
      font-size: 1.7em;
  }

  .card-disclaimer-uk {
      width: 100%;
      padding: 30px 0 0 30px;
      float: none;
  }
}


//============ Total style end ===========
