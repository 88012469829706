//============ Success style start ===========

.phone-spacing {
    margin-top: 10px;
}

.text-green {
    color: #399e1f;
}

.tick-big {
    margin: 0 auto;
    width: 80px;
    height: 80px;
    background-position: -95px -5px;
}

.success-phone {
    top: 9px;
}

//============ Success style end ===========
