//============ Success Confirm style start ===========

.success-title {
    font-size: 2.5em;
    font-weight: 300;
}

.success-title_white {
    color: #fff;
}

.b-improve-text {
    padding-top: 25px;
}

.b-confirm {
    position: relative;
    margin: 40px 0;
    padding: 30px 0 20px;
    border-top: 5px solid #c0c0c0;
    background: #fff;

    &:after {
        clear: both;
    }

    &:after,
    &:before {
        content: " ";
        display: table;
    }
}

.airlines-code-table {
    width: 63%;
}

.b-confirm-table {
    position: relative;
    width: 100%;

    &:before {
        position: absolute;
        top: 50%;
        left: -12px;
        margin-top: -12px;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        display: block;
        box-shadow: -1px 1px 0 rgba(224, 224, 244, 0.4) inset;
        content: " ";
        background: #f4f2f3;
    }

    &:after {
        position: absolute;
        left: 0;
        top: -35px;
        z-index: 10;
        width: 88px;
        height: 5px;
        display: block;
        content: " ";
        background: #379d1d;
    }
}

.b-confirm-table-td {
    padding: 0 20px;
    vertical-align: top;
    font-size: 0.84em;

    &:first-of-type {
        width: 45%;
    }
}

.b-confirm-table-number {
    width: 70px;
    color: #225894;
    font-size: 1.16em;
    font-weight: bold;
}

.b-confirmed {
    color: #379d1d;
    font-weight: bold;
    text-transform: uppercase;
}

.b-resend-table {
    position: relative;

    &:after {
        position: absolute;
        top: 50%;
        right: -12px;
        margin-top: -20px;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        box-shadow: 1px 1px 0 rgba(224, 224, 244, 0.4) inset;
        display: block;
        content: " ";
        background: #f4f2f3;
    }
}

.b-review-block-title {
    position: relative;
    color: #2c3e50;
    font-size: 14px;
    font-weight: bold;
}

.b-review-block-step {
    margin-right: 10px;
    padding-top: 3px;
    width: 25px;
    height: 25px;
    border: 1px solid #2c3e50;
    border-radius: 50%;
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    text-indent: 7px;
    text-align: left;
    background: #fff;
}

.b-improve__item {
    margin-bottom: 20px;
}

.stars {
    margin-top: 10px;
    width: 300px;
    background: url("../../img/stars.png") repeat-x 0 0;

    &:after {
        clear: both;
    }

    &:after,
    &:before {
        content: " ";
        display: table;
    }

    label {
        position: relative;
        z-index: 10;
        margin: 0;
        padding: 0;
        width: 30px;
        height: 30px;
        display: block;
        float: left;
        cursor: pointer;
        background: transparent;
    }

    span {
        position: relative;
        top: 0;
        left: 0;
        width: 0;
        height: 30px;
        transition: width 0.5s;
        display: block;
        background: url("../../img/stars.png") repeat-x 0 -60px;
    }
}

.stars-input {
    position: absolute;
    opacity: 0;
    filter: alpha(opacity=0);

    &.star-10:checked ~ span {
        width: 100%;
    }

    &.star-9:checked ~ span {
        width: 90%;
    }

    &.star-8:checked ~ span {
        width: 80%;
    }

    &.star-7:checked ~ span {
        width: 70%;
    }

    &.star-6:checked ~ span {
        width: 60%;
    }

    &.star-5:checked ~ span {
        width: 50%;
    }

    &.star-4:checked ~ span {
        width: 40%;
    }

    &.star-3:checked ~ span {
        width: 30%;
    }

    &.star-2:checked ~ span {
        width: 20%;
    }

    &.star-1:checked ~ span {
        width: 10%;
    }
}

.textarea {
    padding: 15px 20px;
    width: 100%;
    height: 80px;
    border: 1px solid #adb6bd;
    border-radius: 5px;
    resize: vertical;

    &:focus {
        border-color: #51cbee !important;
        box-shadow: 0 0 5px #51cbee !important;
        outline: none;
    }
}

textarea.ng-invalid-explicit.ng-invalid {
    border: 1px solid #ff4622;
    box-shadow: 0 0 5px #ff4622;
}

.success-message {
    padding: 50px 0;
}

.evaluate {
    position: relative;
    margin-top: 12px;
    margin-bottom: 15px;
    border: 1px solid #cbcccd;
    border-right: 0;
    border-radius: 3px;
    display: inline-block;

    &:after {
        clear: both;
    }

    &:after,
    &:before {
        display: table;
        content: " ";
    }
}

.evaluate__item {
    width: 37px;
    height: 40px;
    border-radius: 3px;
    border: 0;
    border-right: 1px solid #cbcccd;
    float: left;
    color: #777;
    font-weight: bold;
    text-align: center;
    background: #fff;

    &:focus {
        outline: none;
    }

    &:hover {
        cursor: pointer;
    }

    &.active {
        background: #379d1d;
        color: #fff;
        outline: none;
    }
}

.evaluate-text {
    position: absolute;
    bottom: -20px;
    color: #a3a0a0;
    font-size: 9px;
    text-transform: uppercase;

    &.bad {
        left: 0;
    }

    &.good {
        right: 0;
    }
}

.evaluation-title {
    text-align: center !important;
}

.btn_resend {
    margin: 3px 0 0;
    padding: 13px 15px;
    width: 155px;
    box-shadow: 1px 1px 1px #aaa;
    color: #fff;
    background: #379b21;
}

.btn_resend[disabled] {
    background: #eee;
}

.exchange-textarea {
    margin-top: 15px;
}

.b-container_grey-line {
    border-top: 4px solid #ddd6d9;
}

.text-gold {
    color: #ebba51;
}

.text-medium {
    font-size: 1.16em;
}

.tick-small {
    display: inline-block;
    width: 15px;
    height: 12px;
    background-position: -381px -10px;
}

@include desktop {

  .stars {

    label {

      &:hover {

        & ~ span {
          background-position: 0 -30px;
        }

        &.star-10 ~ span {
          width: 100%;
        }

        &.star-9 ~ span {
          width: 90%;
        }

        &.star-8 ~ span {
          width: 80%;
        }

        &.star-7 ~ span {
          width: 70%;
        }

        &.star-6 ~ span {
          width: 60%;
        }

        &.star-5 ~ span {
          width: 50%;
        }

        &.star-4 ~ span {
          width: 40%;
        }

        &.star-3 ~ span {
          width: 30%;
        }

        &.star-2 ~ span {
          width: 20%;
        }

        &.star-1 ~ span {
          width: 10%;
        }
      }

    }
  }
}

@include tablet {
    .b-review-block-step {
        z-index: 10;
        margin: 0 auto;
        display: block;
    }

    .b-review-block-title {
        margin-top: -33px;
        margin-bottom: 5px;
    }

    .btn_resend {
        width: 100%;
        display: block;
    }

    .b-improve__item {
        padding-bottom: 20px;
        border-bottom: 1px solid #cbcccd;
    }

    .stars {
        margin: 0 auto 15px;
    }

    .evaluate__item {
        width: 28px;
        height: 35px;
    }

  .stars {

    span {
      transition-property: none;
    }
  }
}

@include tablet-small {
    .b-confirm-table-td {
        padding-bottom: 20px;
    }

    .b-confirm {
        font-size: 1.16em;
    }

    .airlines-code-table {
        width: 100%;
    }
}

//============ Success Confirm style end ===========
