.datepicker {

  &-title {
    font-size: 14px;
    text-align: center;
    padding: 10px 0;
  }

  &-overlay {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0.3;
  }

  &-container {
    position: absolute;
    width: 377px;
    z-index: 10;
    background: #ffffff;
    box-shadow: 0 2px 22px rgba(0, 0, 0, 0.5);

    &-inner {
      background-color: #ffffff;
      position: relative;
      z-index: 10;
    }

    &.ng-enter, &.ng-leave.ng-leave-active {
      transition: opacity 0.4s;
      opacity: 0;
    }

    &.ng-enter.ng-enter-active, &.ng-leave {
      transition: opacity 0.4s;
      opacity: 1;
    }
  }

  &-dayname {

    &-container {
      padding: 0 10px 10px 10px;
    }

    &-item {
      display: inline-block;
      float: left;
      width: 14%;
      height: 30px;
      line-height: 30px;
      text-align: center;
    }
  }

  &-months {

    &-container {
      padding: 15px 15px 30px 15px;

      select {
        width: 45%;
        border: none;
        border-bottom: 1px solid #e2e2e2;
        background: #ffffff;
        font-family: "Open Sans", sans-serif;
        font-size: 14px;
        color: #2c3e50;
      }
    }
  }

  &-days {

    &.ng-enter, &.ng-leave.ng-leave-active {
      transition: opacity 0.4s;
      opacity: 0;
    }

    &.ng-enter.ng-enter-active, &.ng-leave {
      transition: opacity 0.4s;
      opacity: 1;
    }

    &-container {
      padding:0 10px 15px 10px;
    }

    &-item {
      display: inline-block;
      float: left;
      width: 14%;
      height: 30px;
      line-height: 30px;
      text-align: center;
      cursor: pointer;

      &:hover {
        background-color: #379b21;
        color: #ffffff;
      }

      @for $i from 1 through 6 {
        .day-#{$i} & {
          &:first-child {
            margin-left: calc(14% *  #{$i})
          }
        }
      }

      @for $i from 1 through 31 {
        .chosen-#{$i} & {
          &:nth-child(#{$i}) {
            background-color: #379b21;
            color: #ffffff;
          }
        }
      }
    }
  }

  &-icon {
    position: absolute;
    top: 34px;
    left: 12px;
    user-select: none;
    pointer-events: none;
  }

  select {
    font-family: "Open Sans", sans-serif;
    border: none;
    border-bottom: 1px solid #e2e2e2;
    background: #ffffff;
  }
}

@include tablet {

  .datepicker {
    &-icon {
      top: 15px;
      left: 130px;
    }
  }
}

@include mobile {
  .datepicker {

    &-months {

      &-container {
        padding: 15px;
      }
    }

    &-container {
      width: 100%;
      max-width: 377px;
    }

    &-days {

      &-container {
        padding:0 10px 10px 10px;
      }
    }

    &-dayname {

      &-item {
        display: inline-block;
        float: left;
        width: 14%;
        height: 24px;
        line-height: 24px;
        text-align: center;
      }
    }

  }
}