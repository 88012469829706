.package-summary {
  padding: 20px 0;
  background: #F6F6F6;

  .content-block {
    display: flex;
    flex-wrap: wrap;
  }

  .section {
    padding: 10px 30px 15px 0;
    width: 33.333333%;
    border-right: 2px solid #476CB1;
    color: #131F36;

    &-header {
      display: flex;
      align-items: flex-end;
      margin-bottom: 25px;
      padding-bottom: 30px;
      height: 80px;
      min-width: 250px;
      font-weight: 700;
      border-bottom: 2px solid #379B21;

      p {
        margin: 0;
        line-height: 20px;
      }

      .flight-icon {
        display: inline-block;
        margin-right: 20px;
        min-width: 40px;
        min-height: 40px;
        background: url("../../img/aircraft.png") no-repeat 50% 50%;
      }

      .hotel-icon {
        display: inline-block;
        margin-right: 20px;
        min-width: 48px;
        min-height: 40px;
        background: url("../../img/bed.png") no-repeat 50% 50%;
      }

      .car-icon {
        display: inline-block;
        margin-right: 20px;
        min-width: 54px;
        min-height: 41px;
        background: url("../../img/car.png") no-repeat 50% 50%;
      }
    }

    &-hotel {
      padding-left: 30px;
    }

    &-car-rental {
      padding: 10px 0 15px 30px;
      border: none;

      .rental-request {

        input {
          margin: 0;
          cursor: pointer;
        }

        label {
          cursor: pointer;
        }
      }
    }

    .airline-logo {
      margin-top: 20px;
    }

    .hotel-address {
      margin-top: 20px;
    }
  }
}

@include tablet {

  .package-summary {
    padding-bottom: 0;

    .b-wrapper {
      padding-bottom: 5px;
    }

    .section {
      width: 100%;

      &-flight-to {
        width: 50%;
      }

      &-hotel {
        padding: 10px 0 0 30px;
        width: 50%;
        border: none;
      }

      &-car-rental {
        padding: 35px 0 20px;
        width: 100%;

        .section-header {
          margin: 0;
          padding: 0;
          border: none;
        }

        .rental-request {
          padding-top: 10px;
        }
      }
    }
  }
}

@include mobile {

  .package-summary {
    padding-bottom: 0;

    .section {
      padding: 35px 0;

      &-header {
        height: auto;
      }

      &-flight-to {
        padding-top: 0;
        width: 100%;
        border-right: none;
        border-bottom: 2px solid #476CB1;
      }

      &-hotel {
        width: 100%;
        border-bottom: 2px solid #476CB1;
      }

      &-car-rental {
        display: block;
        padding-bottom: 15px;

        .section-header {
          margin-bottom: 25px;
          padding-bottom: 30px;
          width: 100%;
          border-bottom: 2px solid #BABABA;

          p {
            max-width: 150px;
          }
        }

        .rental-request {
          padding: 0;
          width: 100%;
        }
      }
    }
  }
}
