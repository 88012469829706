.verification {

    &-wrap {
        padding-bottom: 100px;
        text-align: justify;

        .padding {
            padding: 20px 0;
        }

        .margin {
            margin: 20px 0;
        }


        .btn {
            display: inline-block;
            margin: 0;
            font-size: 14px;
            padding: 13px 35px;
        }
    }

    &-input {

      &-container {
        width: 49%;
        float: left;

        &.right {
          padding-left: 130px;

          .hardcopy-mode & {
            padding: 0;
          }
        }

        .textarea {
          margin-top: 60px;
          font-family: "Open Sans", sans-serif;
          resize: none;

          .hardcopy-mode & {
            margin: 0;
            height: 155px;
          }

          &::placeholder {
            font-family: "Open Sans", sans-serif;
            font-size: 12px;
            color: #adb6bd;
          }
        }

        .timezone-select {
          background: none;
          border: none;
          color: #4671cb;

          &:focus {
            background-color:#ffffff;
            outline:none;
            border:none;
            box-shadow:none;
          }

        }
      }
    }

    &-submit {
        width: 347px;
        margin-top: 70px;
    }

    &-error {
      padding: 6px 0;
      display: block;
      clear: both;
      line-height: 16px;
      font-size: 12px;
      text-shadow: 0 1px 1px #fff;
      color: #aa3b30;
      cursor: default;
      font-weight: normal;
    }
}

.intl-input-customize {

  &.ng-invalid.ng-dirty {
    color: #cf0b16;
    border-color: #e7222e;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.23);
  }
}

.input {

  &-row {
    display: inline-block;
    padding-right: 15px;
  }

  &-label {

    .verification-wrap & {
      padding-top: 15px;
      padding-bottom: 15px;
      cursor: default;
    }
  }

  &-amount {
    text-align: right;
    width: 120px;
  }

  &-phone {
    width: 320px;
    padding: 8px !important;

    &-row {
      display: block;
      line-height: 40px;
      cursor: pointer;

      label {
        cursor: pointer;
      }
    }

    &-radio {
      margin: 0 4px;
      vertical-align: middle;
      cursor: pointer;

      &:checked {

        + label {

          font-weight: 700;
        }
      }
    }
  }
}

.anchor {
  text-decoration: underline;
  font-size: 14px;
  color: #3d6abd;
  cursor: pointer;
  border: none;
  background: none;
  outline: none;
  margin: auto;
  display: block;

  &.phone {
    margin: 0;
  }
}

.currency {
  position: absolute;
  display: inline-block;
  pointer-events: none;
  line-height: 50px;
  padding-left: 10px;
}

.inline-anchor {
  display: inline-block;
  margin-left: 20px;
}


@include tablet {

    .verification {

        &-wrap {

            .input-label {
                text-align: left;
            }

            .btn_green {
                width: auto;
            }
        }

    }
}

@include mobile {

    .verification {

        &-wrap {

            .input {

                &-label {
                    text-align: center;
                }

                &-row {
                    width: 100%;
                    padding-bottom: 10px;
                }

                &-phone {
                    width: calc(100% - 28px);
                }
            }

            .center {
                text-align: center;
            }

            .btn_green {
                width: 100%;
            }
        }

        &-submit {
          width: 100%;
          text-align: center;
        }

        &-phone-container {
          width: 100%;
          max-width: 350px;
          margin: 0 auto;
        }

        &-input {

          &-container {
            width: 100%;
            float: none;

            &.right {
              padding-left: 0;
            }

            &.timezone {
              padding: 0 16px;
            }

            .timezone-select {
              display: block;
              margin: 0 auto;
            }
          }
        }

        &-timezone-container {
          text-align: center;
        }
    }

  .inline-anchor {
    margin: 20px auto;
    display: block;
  }
}

.intl {

  &-input {

    &-customize {
      width: 300px;
      height: 38px;
      padding: 6px 12px;
      font-size: 14px;
      line-height: 1.42857143;
      color: #555;
      background-color: #fff;
      background-image: none;
      border: 1px solid #ccc;
    }
  }

  &-tel {

    &-input {

      .selected-flag {
        border: none;
        outline: none;
      }
    }
  }
}

