//============ Flight page style start ===========

.oaf-title {
    padding-bottom: 20px;
}

.b-ticket-header {
    padding: 15px 25px;
    border: 3px solid #2e74ba;
    border-radius: 5px 5px 0 0;
    background: #2e74ba;
}

.b-ticket-heading {
    color: #fff;
    font-weight: 600;
    font-size: 1.7em;
}

.flight {

  &-duration {

    &-header {
      padding-top: 5px;
      color: #fff;
      font-size: 1.16em;
      font-weight: 600;
    }
  }

  &-content {
    margin-bottom: 20px;
    border: 1px solid #d6d6d6;
    border-top: 0;
    border-radius: 5px 5px 0 0;
    box-shadow: 0 4px 0 0 #ccc;
  }

  &-info {
    margin: 0 20px 20px 65px;

    &_arrival {
      position: relative;

      .flight-airport:before {
        height: 23px;
        background: url("../../img/icon-sprite.png") -237px -5px;
      }
    }

    &_depart {
      position: relative;

      .flight-airport:before {
        top: 5px;
        height: 19px;
        background: url("../../img/icon-sprite.png") -271px -5px;
      }
    }

    &-full {
      padding-top: 20px;
      border-right: 1px dashed #b5b5b5;
    }
  }

  &-airport {
    margin-bottom: 25px;
    color: #385173;
    font-size: 1.16em;
    font-weight: 600;

    &:before {
      position: absolute;
      left: -40px;
      width: 26px;
      content: "";
    }
  }

  &-time,
  &-date,
  &-destination {
    margin-bottom: 5px;
    color: #385173;
    font-size: 1.13em;
  }

  &-destination {
    margin-bottom: 45px;
  }

  &-time {
    font-size: 1.53em;
  }

  &-airline {
    padding: 20px;
    height: 215px;
    text-align: center;

    &-name {
      margin-top: 10px;
      margin-bottom: 10px;
      height: 30px;
      color: #385173;
      font-size: 1em;
      font-weight: 600;
      text-transform: uppercase;
    }

    &-class {
      margin-bottom: 10px;
      height: 20px;
      text-transform: capitalize;
      color: #385173;
    }

    &-info {
      margin-bottom: 5px;
      height: 30px;
      color: #5c656f;
      font-size: 0.84em;
      text-transform: uppercase;
    }
  }

  &-duration {
    height: 15px;
    font-size: 0.90em;
    font-weight: 700;

    span {
      font-weight: normal;
    }
  }

  &-layover {
    padding: 7px;
    font-size: 1.16em;
    background: #fcefc0;

    span {
      font-weight: 600;
    }
  }
}

.service-airline-block {
    position: absolute;
    top: -120px;
    right: 0;
}

.qa-airline-logo {
    max-width: 80px;
    max-height: 40px;
}

.airport-title {
  color: #97b0ef;
}

@include tablet-small {

    .text-center-xs {
      text-align: center;
    }

    .flight {

        &-info {
          margin-bottom: 20px;
          height: auto;
        }

        &-duration {
          padding-top: 10px;
          height: 25px;
        }

        &-airline {
          height: 250px;

          &-name, &-class {
            min-height: 25px;
          }

          &-info {
            height: 40px;
          }
        }

        &-airport {
          height: 40px;
          margin-bottom: 35px;
        }

        &-destination {
          margin-bottom: 35px;
          height: 55px;
        }
    }
}

@include mobile {
  .col-12-xxs {
    position: relative;
    width: 100%;
  }

  .col-4-xxs {
    width: 95px;
    display: inline-block;

    img {
      position: absolute;
      top: 15px;
    }
  }

  .col-8-xxs {
    width: 58%;
    display: inline-block;
    text-align: left;
  }

  .service-airline-block {
    position: initial;
  }

  .oaf-title {
    text-align: left;
  }

  .b-ticket-heading {
    padding: 10px;
    color: #f6f7f8;
    text-align: left;
    background-color: #2872af;
  }

  .b-ticket-header {
    padding: 0;
    border: 0;
  }

  .flight {

    &-duration-header {
      padding: 10px;
      color: #2872af;
      text-align: left;
      background-color: #f6f7f8;
    }

    &-airline {
      height: 100%;
      text-align: left;
      background-color: #f6f7f8;
      width: 100%;
      border: 0;

      &-info {
        min-height: 25px;
      }

      &-class {
        margin-bottom: 0;
      }
    }

    &-info {
      margin-left: 70px;
      word-wrap: break-word;

      &-full {
        width: 100%;
        border: 0;
      }

      &_arrival {
        padding-top: 20px;
        border-top: 1px dashed #d7d7d7;

        .flight-airport:before {
          top: 25px;
        }
      }
    }

    &-airport {
      margin-bottom: 0;
      height: 35px;
    }

    &-destination {
      margin-bottom: 10px;
      padding: 10px 0;
    }
  }
}

//============ Flight page style end ===========
