//============ Footer style start ===========

.footer-wrapper {
    border-top: 1px solid #adb6bd;
}

.footer-container {
    margin: 0 auto;
    padding: 20px 15px;
    max-width: 975px;

    &::after {
        display: table;
        content: '';
        clear: both;
    }
}

.footer-logo {
    margin-right: 20px;
    width: 160px;
    display: block;
    float: left;
}

.itn-block {
    padding-left: 20px;
    width: 385px;
    border-left: 1px solid rgba(0,0,0,0.2);
    float: left;

    .footer-uk & {
        width: 335px;
    }

    p {
        padding-left: 10px;
        width: 300px;
        display: inline-block;
        color: #5c656f;
        font-size: 12px;
        vertical-align: middle;

        .footer-uk & {
            width: 250px;
        }
    }
}

.footer-left {
    float: left;
}

.footer-copy {
    margin-top: 10px;
    color: #5c656f;
    font-size: 12px;
}

.footer-sprite {
    background: url('../../img/footer-sprite.png') no-repeat;
    display: inline-block;

    .footer-uk & {
        vertical-align: top;
    }
}

.arc3 {
    width: 30px;
    height: 45px;
    background-position: -5px -5px;
}

.asap {
    width: 155px;
    height: 31px;
    background-position: -45px -5px;
}

.asta {
    width: 62px;
    height: 40px;
    background-position: -210px -5px;
}

.bbb {
    display: inline-block;
    width: 50px;
    height: 45px;
    background: url("../../img/bbb-a.jpg") 10px 0 no-repeat;

    &-logo {
        border-left: 1px solid rgba(0,0,0,0.2);
    }
}

.iatan {
    width: 50px;
    height: 40px;
    background-position: -339px -5px;
}

.itn {
    width: 53px;
    height: 36px;
    vertical-align: middle;
    background-position: -399px -5px;
}

.optimus-footer {
    width: 119px;
    height: 40px;
    background-position: -462px -5px;
}

.atol {
    width: 50px;
    height: 50px;
    background-position: -0px -55px;
}

.atol-logo {
    opacity: 0.3;
    display: none !important;

    .footer-uk & {
        display: inline-block !important;
    }
}


@include tablet {
    .footer-container {
        text-align: center;
    }

    .footer-left {
        margin: 10px 0;
        display: inline-block;
        float: none;
        text-align: left;
    }

    .footer-partners {
        width: 100%;
        text-align: center;
    }

    .footer-copy {
        text-align: center;
    }
}

@include tablet-small {
    .footer-logo {
        margin: 0 auto;
        width: 155px;
        float: none;
    }

    .itn-block {
        margin: 10px auto;
        padding: 0;
        width: 100%;
        border: 0;
        float: none;
    }

    .footer-uk {
        .itn-block {
            width: 100%;
        }

        .bbb-logo {
            background-position-x: 0;
            border: none;
        }

        .optimus-footer {
            width: 102px;
            background-position: -467px -5px;
        }

        .iatan {
            width: 40px;
            background-position: -344px -5px;
        }
    }
}

//============ Footer style end ===========
