//============ Passengers style start ===========

.ticket-price {
  display: inline-block;
  font-weight: 700;
  padding-left: 20px;
}

.form-info-block {
    margin-bottom: 10px;
    padding: 13px 20px;
    border-radius: 3px;
    border: 1px solid #e2e2e2;
}

.passenger-seperator {
    margin-top: 10px;
    max-width: 945px;
}

.program {
  overflow: hidden;

  &.ng-enter {
    transition: max-height 0.5s ease-in, padding 0.5s ease-in;
    max-height: 0;
    padding: 0 15px;
  }

  &.ng-enter.ng-enter-active {
    max-height: 580px;
    padding: 15px 15px;
  }

  &.ng-leave {
    transition: max-height 0.5s ease-out, padding 0.5s ease-out;
    max-height: 580px;
    padding: 15px 15px;
  }

  &.ng-leave.ng-leave-active {
    max-height: 0;
    padding: 0 15px;
  }
}

.program-select {

  .hardcopy-highlight & {

    background-color: #FFBABA !important;

    &:before {
      background-color: #FFBABA !important;
    }
  }
}

.program-delete {
  display: inline-block;
  background-position: -260px -9px;
  cursor: pointer;
}

.program-add {
  display: inline-block;
  background-position: -225px -9px;
  cursor: pointer;
}

.repeater {

  &-block {
    position: relative;
    margin-top: 37px;
    width: 140px;
    display: block;
  }

  &-text {
    display: inline-block;
    vertical-align: top;
    border: 0;
    color: #19365e;
    line-height: 24px;
    background: inherit;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &-group {
    position: relative;
  }
}

.redress-checkbox {
  top: 5px;
}

.b {

  &-tooltip {

    &-close {
      position: absolute;
      top: 0;
      right: 4px;
      cursor: pointer;
    }

    &_inline {
      position: relative;
      top: -7px;
      width: 200px;
      display: inline-block;
    }

    &_gender {
      top: 32px;
      right: 35px;

      .lang-es & {
        right: 5px;
      }
    }

    &-text {

      &-redress {
        right: -120px;

        &.b-tooltip-text:before {
          left: 90px;
        }
      }
    }
  }
}


.ff {

  &-error {
    border-bottom: none;
  }

  &-program-number {
    margin-bottom: 0;

    .hardcopy-highlight & {
      background-color: #FFBABA;
    }
  }
}


.input-label {
  &.gender {
    margin: 9px 0 12px;
  }

  .name-mobile {
    display: none;
  }
}

@include tablet {
    .b-tooltip_gender {
        top: auto;
        right: auto;
    }

    .b-tooltip-text_redress.b-tooltip-text:before {
        left: 50%;
    }

    .repeater-delete {
        position: relative;
        top: auto;
        right: auto;
    }

    .repeater-add,
    .repeater-delete {
        display: inline-block;
    }

    .custom-dropdown_indent {
        margin-bottom: 4px;
    }

    .input_padding {
        padding-left: 100px;
    }

    .repeater-add-block,
    .repeater-delete-block {
        margin: 10px auto;
    }

    .program {

      &.ng-enter.ng-enter-active {
        max-height: 1100px;
      }

      &.ng-leave {
        max-height: 1100px;
      }
    }

  .input-label {

    .name-mobile {
      display: inline-block;

      &-details {
        font-size: 0.74em;
        line-height: 13px;
      }
    }
  }
}

@include mobile {

    .frequent-title {
        text-align: left;
    }
}

//============ Passengers style end ===========
