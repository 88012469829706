//============ Protect style start ===========

.itn {

  &-protection {

    &-left {

      &-container {
        width: calc(50% - 10px);
        display: inline-block;
        float: left;
      }
    }

    &-right {

      &-container {
        padding-left: 20px;
        width: calc(50% - 10px);
        display: inline-block;
        float: right;
      }
    }

    &-header {

      &_new {
        padding-left: 55px;

        &:before {
          position: absolute;
          left: 0;
          width: 44px;
          height: 44px;
          display: block;
          content: '';
          background: #fff url("../../img/tp-sprite.png") -5px -5px no-repeat;
        }
      }
    }

    &-title {
      margin-bottom: 20px;
      color: #19365e;
      font-size: 18px;

      .lang-es & {
        font-size: 17px;
      }
    }

    &-pre-title {
      margin-top: 4px;
      color: #19365e;
      font-size: 12px;
      font-weight: 400;
    }

    &-price {
      position: relative;
      padding-top: 10px;
      display: block;
      color: #19365e;
      font-size: 22px;
      font-weight: bold;
      text-align: center;

      sup {
        position: relative;
        top: 3px;
        font-size: 0.65em;
        vertical-align: text-top;
      }

      &_inline {
        display: inline-block;
      }
    }

    &-list {
      margin: 14px 0 20px;
      padding: 0;
      list-style: none;

      &-blocks {
        padding: 0 10px 0 30px;
      }

      &-block {
        position: relative;

        &-1 {
          padding-right: 30px;

          .baggage-mishandle {
            margin-bottom: 20px;
          }
        }

        &-3 {
          padding-right: 30px;
        }

        &-title {
          padding-left: 43px;
          display: inline-block;
          color: #19365e;
          font-size: 20px;
          font-family: "Bebas", sans-serif;
          line-height: 33px;
          text-transform: uppercase;

          &:before {
            position: absolute;
            margin-left: -43px;
            width: 36px;
            height: 35px;
            display: block;
            content: '';
            background: url("../../img/tp-sprite.png") no-repeat -57px -5px;
          }

          &.tp-icon-2::before {
            background: url("../../img/tp-sprite.png") no-repeat -98px -5px;
          }

          &.tp-icon-3::before {
            background: url("../../img/tp-sprite.png") no-repeat -141px -5px;
          }

          &.tp-icon-4::before {
            background: url("../../img/tp-sprite.png") no-repeat -184px -5px;
          }
        }

        &-apply {
          width: 240px;
          color: #19365e;
          position: absolute;
          top: 28px;
          left: 48px;
          font-size: 12px;
        }

        &-sub-description {
          margin: 14px 0 0;
          padding: 0;
          color: #6d7d91;
          font-size: 12px;
          line-height: 19px;
        }
      }

      &__item {
        position: relative;
        margin: 0 0 6px;
        padding-left: 33px;
        color: #19365e;
        font-size: 12px;

        &:before {
          content: "";
          position: absolute;
          top: 2px;
          left: 6px;
          width: 15px;
          height: 12px;
          background: url("../../img/button-sprite.png") -381px -10px;
        }
      }
    }

    &-video {

      &-block {
        display: block;
        margin-bottom: 12px;
        height: 185px;
      }
    }

    &-dont-miss {
      padding: 12px 40px;
      min-height: 16px;
      text-align: center;
      background-color: #f4f2f3;

      &-text {
        position: relative;
        margin: 0 auto;
        color: #19365e;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        display: inline-block;
        padding: 0 5px;

        &::before {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: -15px;
          width: 15px;
          height: 20px;
          content: '';
          background: url('../../img/tp-sprite.png') -228px -5px no-repeat;
        }
      }
    }
  }
}

.popup.terms {
  left: 50%;
  transform: translate(-50%);
}

.video {

  &-tag {
    position: relative;
    z-index: 10;

    &.ng-enter,
    &.ng-leave {
      transition: opacity 0.5s;
    }
    &.ng-enter,
    &.ng-leave.ng-leave-active {
      opacity: 0;
    }
    &.ng-leave,
    &.ng-enter.ng-enter-active {
      opacity: 1;
    }
  }

  &-overlay {
    width: 100%;
    height: 100%;
    background: url("../../img/video-overlay.png") no-repeat;
    background-size: cover;

    &:hover {
      cursor: pointer;
    }
  }

  &-block {

    &-title {
      height: 85px;
      background: rgba(255,255,255,0.6);

      p {
        padding: 10px 0 0 15px;
        width: 75%;
      }
    }
  }

  &-play {
    position: relative;
    z-index: 1;
    top: 10%;
    left: 40%;
    width: 90px;
  }

  &-popup {

    &-container {
      position: fixed;
      left: calc((100% - 720px) / 2);
      top: calc((100% - 405px) / 2);
      z-index: 12;
      background-color: #000000;
    }

    &-close {
      position: absolute;
      z-index: 10;
      top: -20px;
      right: -20px;
      width: 34px;
      height: 34px;
      cursor: pointer;
      background: url("../../img/button-sprite.png") -142px -10px;
    }
  }
}

.choose-protection {
    margin-bottom: 25px;
    padding: 10px 30px 10px 10px;
    background: #faedc6;
    font-size: 13px;
    font-weight: 700;
    line-height: 22px;

    .form-group {
        margin: 0;
    }
}

.block-pre-title {
    font-weight: normal;
}

.no-protection {
    position: relative;
    margin-bottom: 16px;
    padding-left: 24px;
    font-weight: 700;
    font-size: 13px;

    &:before {
        position: absolute;
        left: 0;
        top: 1px;
        width: 13px;
        height: 13px;
        content: " ";
        background: url("../../img/button-sprite.png") -295px -10px;
    }
}

.no-insurance-policy {
  margin: 15px 0 15px;
  font-size: 13px;
}

.js-show-popup {
    color: #19365e;
}

.protection-title {
    width: 200px;
    font-size: 1.4em;
}

.video-play-icon {
    width: 50px;
    height: 50px;
    background-position: -185px -5px;
}

.hardcopy-tp-terms {
    padding: 10px 30px;
    text-align: justify;
    font-size: 12px;

  .block-title {
    padding-bottom: 10px;
  }
}

.get-voucher {
  margin-bottom: 15px;
  font-size: 18px;
  line-height: 20px;
  color: #19365e;

  span {
    font-weight: bold;
  }
}

@include tablet {
    .b-protection {
        padding-right: 0;
        margin-bottom: 20px;
    }

    .block-pre-title {
        text-align: center;
    }
}

@include tablet-small {
    .video-popup-container {
        width: 300px;
        left: calc((100% - 300px) / 2);
        top: calc((100% - 169px) / 2);

        embed,
        iframe,
        object,
        video {
          width: 100%;
          height: 169px;
        }
    }

    .protection-title {
        width: 100%;
    }
}

@include mobile-large {
    .itn-protection-list-block-title {
        margin-bottom: 12px;
        line-height: 20px;
    }

    .itn-protection-pre-title,
    .itn-protection-title {
        margin-top: 15px;
        font-size: 14px;
    }

    .itn-protection-price {
        font-size: 20px;
    }
}

@include mobile {
    .itn-protection-left-container,
    .itn-protection-right-container {
        width: 100%;
        padding: 0;
    }
}

//============ Protect style end ===========
