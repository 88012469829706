.visa {

  &-element {
    position: relative;
    display: block;
    width: 100%;
  }

  &-button {
    display: block;
    position: relative;
    padding: 11px 20px;
    font-size: 14px;
    color: #379b21;
    border: 2px solid #379b21;
    background-color: #ffffff;

    &:hover {
      color: #ffffff;
      background-color: #379b21;
    }

    &:after {
      content: "*";
      position: absolute;
      top: 5px;
      right: 5px;
      font-size: 20px;
    }
  }

  &-popup {

    &-container {
      position: absolute;
      width: 759px;
      padding: 30px;
      z-index: 4;
      background-color: #ffffff;
      border-radius: 4px;
      left: calc((100% - 760px) / 2);
      top: 50%;
      transform: translateY(-50%);
    }

    &-content {

      .visa {

        &-nationality {
          display: inline-block;
          width: 270px;
          padding-right: 20px;
        }

        &-residence {
          display: inline-block;
          width: 200px;
          padding-right: 20px;
        }

        &-info {
          width: 215px;
          display: inline-block;
          vertical-align: bottom;
        }
      }
    }

    &__close {
      position: absolute;
      z-index: 10;
      top: 20px;
      right: 20px;
      width: 34px;
      height: 34px;
      cursor: pointer;
      background: url("../../img/button-sprite.png") -142px -10px;
    }

    &__preloader {
      display: block;
      margin: 10px auto;
    }

    &-disclaimer {
      padding: 7px 0;
      text-align: justify;
    }
  }

  &-submit {

    &.btn {
      width: 100%;
      padding: 14px 5px 15px 5px;
      margin: 0;
      opacity: 1;
      background-color: #379b21;
      font-size: 14px;
      font-weight: 700;
      line-height: 16px;
      color: #ffffff;
      transition: all 0.3s linear;

      &:disabled {
        transition: all 0.3s linear;
        opacity: 0.5;
        background-color: #cccccc;
      }
    }
  }

  &-row {
    position: relative;
    padding: 15px 0;
    color: #34495e;
    font-size: 12px;
  }

  &-text {
    padding-top: 20px;
    max-height: 290px;
    overflow-y: auto;
  }
}

@include tablet-small {

  .visa {

    &-popup {

      &-container {
        width: 100%;
        top: 0;
        left: 0;
        padding: 10px 15px;
        transform: none;
        border-radius: 0;

        .visa {

          &-nationality {
            width: 100%;
            padding-right: 0;
            margin-bottom: 7px;
          }

          &-residence {
            width: 100%;
            padding-right: 0;
            margin-bottom: 10px;
          }

          &-info {
            width: 100%;
            padding-right: 0;
          }
        }

        .block-title {
          margin-bottom: 0;
        }
      }

      &__close {
        top: 4px;
        right: 4px;
      }

      &-content {

        .custom-dropdown {
          height: 30px;

          &:before {
            height: 26px;
          }

          &:after {
            top: 11px;
          }

          select {
            height: 27px;
          }
        }
      }
    }

    &-submit {

      &.btn {
        width: 215px;
        padding: 7px 5px 7px 5px;
      }
    }
  }
}