.autoc {

  &-container {
    position: relative;
    margin-right: 5px;
    margin-top: 3px;
    width: 100%;
    height: 44px;
    display: inline-block;
    vertical-align: middle;

    &:before {
      position: absolute;
      top: 1px;
      right: 1px;
      width: 24px;
      height: 38px;
      content: " ";
      pointer-events: none;
    }

    &:after {
      position: absolute;
      top: 18px;
      right: 8px;
      width: 17px;
      height: 10px;
      content: " ";
      background: url("../../img/button-sprite.png") -318px -10px;
      pointer-events: none;
      color: rgba(0, 0, 0, 0.4);
    }

    .input {
      margin: 0;

      .disabled & {
        background-color: #eeeeee;
      }
    }

    .disabled & {
      user-select: none;
      pointer-events: none;
    }
  }

  &-result {

    &-container {

      position: relative;
      background-color: #ffffff;
      border: 1px solid #adb6bd;
      border-top: none;
      overflow: auto;
      z-index: 15;
      max-height: 215px;
    }

    &-item {
      padding: 10px 0 10px 10px;
      list-style: none;
      line-height: 14px;
      cursor: pointer;

      &:hover {
        background-color: #379b21;
        color: #ffffff;
      }

      &.not-found {
        color: #ff4622;

        &:hover {
          color: #ff4622;
          background-color: #ffffff;
        }
      }
    }
  }
}


@include tablet {

  .autoc {

    &-container {
      height: auto;
      margin-top: 0;

      .input {
        height: auto;
      }
    }
  }
}