
.b-tips-container {
  margin-top: 8px;
  display: inline-block;
  line-height: 17px;

  p {
    margin: 0;
    font-size: 16px;
  }

  .tips-chosen-text {
    font-size: 14px;
    font-weight: 400;
    font-size-adjust: 0.5;
    color: #2c3e50;
  }
}

.tip {

  &-box {

    &-header {
      position: relative;
      height: 76px;
      padding: 0 36px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      background-color: #2473b0;
    }

    &-left {
      position: relative;
      display: inline-block;
      float: left;
      width: 510px;
      color: #2c3e50;
    }

    &-right {
      position: relative;
      display: inline-block;
      float: left;
      width: 150px;
      color: #2c3e50;
      text-align: center;

      &-title {
        margin-top: 15px;
        margin-bottom: 12px;
        width: 90px;
        display: inline-block;
        font-size: 12px;
        font-weight: bold;
        line-height: 14px;
        text-align: center;
      }
    }

    &-bottom {
      display: inline-block;
      float: left;
      width: 100%;
      padding: 19px 0 0 37px;

      .btn_tips {
        width: 122px;
        float: none;
        margin-bottom: 15px;
      }
    }
  }

  &-close-icon {
    position: absolute;
    top: 29px;
    right: 20px;
    width: 16px;
    height: 16px;
    cursor: pointer;
    background: url("../../img/button-sprite.png") -433px 0;
  }

  &-agent-photo {
    border-radius: 50%;
    background-color: #ffffff;
  }

  &-header-text {
    padding-left: 15px;
    text-transform: uppercase;
    color: #ffffff;
    font-size: 19px;
  }

  &-text {
    margin-top: 22px;

    &-mobile {
      padding-top: 20px;
      text-align: center;
      color: #2b4053;
      font-size: 16px;
      font-weight: 700;
    }
  }

  &-block {

    &-right {
      padding-left: 30px;
      border-left: 1px solid #d5d5d5;
    }

    &-left {
      padding-right: 20px;
    }
  }
}

.tips_input {
  width: 50px;
  border: none;
  padding: 0;
  display: inline-block;
  text-align: left;
  font-size: 16px;
  color: #2c3e50;
  font-weight: bold;

  &::placeholder,
  &:read-only {
    color: #2c3e50;
  }
}

.btn_tips {
  float: right;
  width: 102px;
  height: 47px;
  display: inline-block;
  padding: 0;
  vertical-align: top;
  font-size: 14px;
  margin: 0;
}

.right-padding {
  padding-right: 10px;
}

.btn-confirm {
  margin: 2px 0;
  padding: 14px 18px;
  display: inline-block;
  float: right;
  font-size: 14px;
}

.tip-amount-box {
  margin: 20px 15px 20px 0;
}

.invalid-tip {
  border: 1px solid #ff4622 !important;
  box-shadow: 0 0 5px #ff4622;
}

.tip-input-tickmarks {
  position: relative;
  height: 8px;
  width: 440px;
  margin: 60px 35px 30px;
  z-index: 1;

  .noUi {

    &-base {
      top: -10px;
      padding-top: 10px;
      padding-bottom: 10px;
    }

    &-horizontal {
      height: 2px;
    }

    &-handle {
      height: 20px;
      width: 20px;
      top: 10px;
      right: -13px;
      box-shadow: 1px 1px 1px #000;
      border: 1px solid #379d1d;
      background: #379d1d;
      cursor: pointer;
      -webkit-appearance: none;
      margin: -10px 0 0;
    }

    &-pips {
      color: #000000;

      &-horizontal {
        left: 0;
        top: -13px;
      }
    }

    &-value {
      cursor: pointer;

      &-sub {
        color: #000000;
      }

      &-horizontal {
        top: 36px;
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
        transform: translate(-43%);
      }
    }

    &-marker {
      background: #e6e9eb;

      &-sub,
      &-large {
        background: #e6e9eb;
      }

      &-horizontal {

        &.noUi-marker {
          width: 8px;
          height: 8px;
          border-radius: 50%;

          &-sub {
            height: 10px;
          }
        }
      }
    }
  }
}

.tip-box-popup {
  position: absolute;
  top: -281px;
  left: 23px;
  width: 661px;
  height: 290px;
  box-shadow: 0 6px 58px rgba(0,0,0,0.6);
  color: #000;
  background: #fff;
  z-index: 99;

  &:after {
    position: absolute;
    bottom: -18px;
    left: 0;
    width: 0;
    height: 0;
    display: block;
    content: "";
    border-style: solid;
    border-width: 20px 20px 0 0;
    border-color: #ffffff transparent transparent transparent;
  }

  .tips {
    width: 90px;
  }

  .top-elements {
    position: absolute;
    width: 531px;
    height: 30px;
    padding: 10px 0;
    margin-left: 10px;
    color: #2b4053;
    font-size: 12px;
    font-weight: 700;
    text-align: left;
  }

  .tiptext {
    display: inline-block;
    text-align: center;
    width: 13%;
    vertical-align: middle;
    padding-right: 9px;
    cursor: pointer;
  }

  .b-tooltip {

    &-text {
      top: 110px;
      left: -60px;
      right: 0;
      width: 285px;
      margin: 0;
      display: inline-block;
      visibility: visible;
      opacity: 1;
      font-size: 10px;

      &:before {
        position: absolute;
        top: -5px;
        left: 36%;
        border-style: solid;
        border-width: 0 5px 7px 5px;
        border-color: transparent transparent #f6eed6 transparent;
      }
    }
  }

  &__overlay {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 1;
    background: #000000;
    opacity: 0;
  }
}


.tip-container-title {

  .bog-sale & {
    display: none;
  }

  &-bog {
    display: none;

    .bog-sale & {
      display: inline;
    }
  }
}


@include desktop {
  .lang-es {

    .top-elements {
      width: 690px;
      margin: 0;
      font-size: 11px;
    }

    .tip-box-popup {
      width: 800px;

      .tiptext {
        width: 13%;
        margin-right: 3px;
      }
    }

    .tip-input-tickmarks {
      width: 580px;
    }

    .tip-box-left {
      width: 650px;
    }

    .tip-box-right .tip-box-right-title {
      width: 98px;
    }
  }
}

@include tablet {

  .tips {
    padding-left: 5px !important;
    border: 1px solid #adb6bd !important;
  }

  .tip {

    &-box {

      &-popup {
        width: 510px;
        left: 50%;
        transform: translate(-50%);

        .b-tooltip-text {
          top: 94px;
          left: 40px;
        }

        .top-elements {
          text-align: center;
          font-size: 16px;
          margin-left: 10px !important;
        }
      }

      &-left {
        width: 100%;
      }

      &-bottom {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 50%;
        text-align: right;
        padding: 19px 30px 0 37px;
      }

      &-right {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 50%;
        text-align: center;

        .tip-box-right-title {
          font-size-adjust: 0.5;
          display: block;
          width: 100%;
        }

        input {
          margin-bottom: 15px !important;
          width: 122px;
        }
      }
    }

    &-block {

      &-left {
        padding-right: 0;
      }

      &-right {
        border: none;
        padding-left: 0;
      }
    }

    &-amount-box {
      justify-content: space-around;
    }

    &-input {

      &-tickmarks {
        margin: 20px 35px;
        width: 440px;
      }
    }
  }

  .b-tips-container {
    margin-left: 50px;
    float: left;
  }
}

@include mobile-large {

  .tip {

    &-amount-box {
      margin: 20px 0;
    }

    &-close-icon {
      top: 21px;
    }

    &-input-tickmarks {
      width: 270px;
      margin: 10px auto;

      .noUi-value-horizontal {
        font-size: 11px;
      }
    }

    &-box {

      &-popup {
        width: 340px;
        height: 250px;

        .lang-es & {
          height: 275px;
        }

        .top-elements {
          width: 301px !important;
          margin: 10px !important;
        }

        .b-tooltip-text {
          top: unset;
          bottom: -50px;
          min-width: 210px;
          width: 210px;
        }
      }

      &-header {
        height: 68px;
      }

      &-bottom {
        padding: 15px 15px 0;
      }

      &-right {
        text-align: center;
      }
    }

    &-text-mobile {
      padding-top: 10px;
    }
  }
}