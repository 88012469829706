.hotel {

  &-info {
    margin-top: 10px;

    .content-block {
      margin-top: 10px;
      display: flex;
      align-items: center;
    }
  }

  &-image {
    position: relative;
    width: 100%;
    max-height: 300px;
    vertical-align: middle;

    &-item {
      display: inline-block;
      text-decoration: none;
      width: 33.333333%;
      padding-right: 10px;

      &:last-of-type {
        padding-right: 0;
      }
    }
  }

  &-title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    font-weight: 700;

    &-block {
      display: inline-block;
    }
  }

  &-price {
    color: #BCBDBC;
  }

  &-stars {
    display: inline-block;
    margin-left: 20px;
    height: 10px;
    background: url("../../img/hotel-star.png");

    &.size-0 {
      width: 0;

      &.half {
        width: 7px;
      }
    }

    &.size-1 {
      width: 13px;

      &.half {
        width: 20px;
      }
    }
    &.size-2 {
      width: 26px;

      &.half {
        width: 33px;
      }
    }
    &.size-3 {
      width: 39px;

      &.half {
        width: 46px;
      }
    }
    &.size-4 {
      width: 52px;

      &.half {
        width: 59px;
      }
    }
    &.size-5 {
      width: 65px;
    }
  }

  &-description {
    font-size: 14px;
    line-height: 12px;

    &-wrapper {
      width: 100%;
    }

    &-row {
      display: flex;
      margin-bottom: 5px;

      .label {
        min-width: 180px;
        font-weight: 700;
        line-height: 20px;
      }

      .text {
        line-height: 20px;
      }
    }
  }

  &-amenities {
    font-size: 14px;
    line-height: 20px;
    text-align: justify;

    &-title {
      display: inline-block;
      width: 176px;
      font-size: 14px;
      margin-bottom: 0;
      font-weight: 700;
    }
  }
}

.charge-at-check-in {
  display: flex;
  padding: 10px 0 0 5px;

  .asterisk {
    margin-right: 5px;
  }

  .notification {
    font-size: 14px;
  }
}

@include tablet {

  .hotel {

    &-title {
      flex-direction: column;

      &-block {
        margin-bottom: 10px;
      }
    }
  }
}

@include mobile {

  .hotel {

    &-info {
      margin: 15px 0 0;

      .content-block {
        flex-direction: column;
      }
    }

    &-image {
      position: relative;
      width: 100%;
      vertical-align: middle;
      top: 0;
      transform: none;

      &-item {
        display: inline-block;
        text-decoration: none;
        width: 100%;
        padding: 10px 0;
      }
    }

    &-description {
      margin-bottom: 10px;
    }

    &-amenities {

      &-title {
        width: auto;
      }
    }
  }
}
