/*

  Custom stylings for nouislider, used in conference call verification page

*/

.noUi {

    &-container {
      margin-top: 45px;
      display: block;
      width: 100%;
      padding: 0 35px 0 25px;
    }

    &-target {
      position: relative;
      direction: ltr;
      -webkit-touch-callout: none;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      touch-action: none;
      user-select: none;
      box-sizing: border-box;
      background: #e0e0e0;

      * {
        -webkit-touch-callout: none;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        touch-action: none;
        user-select: none;
        box-sizing: border-box;
        outline: none;

        &:focus {
          outline: none;
        }
      }
    }

    &-base {
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 1;
    }

    /* Wrapper for all connect elements*/
    &-connects {
      width: 100%;
      height: 100%;
      position: relative;
      overflow: hidden;
      z-index: 0;
      border-radius: 3px;
    }

    &-connect,
    &-origin {
      will-change: transform;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      transform-origin: 0 0;
    }

    &-connect {
      background: #379b21;
    }

    &-horizontal {
      height: 4px;

      .noUi {

        &-origin {
          height: 0;
        }

        &-tooltip {
          transform: translate(-50%, 0);
          left: 50%;
          bottom: -180%;
        }
      }
    }

    &-handle {
      position: absolute;
      right: -17px;
      left: auto;
      top: -9px;
      width: 21px;
      height: 21px;
      border: 1px solid #D9D9D9;
      border-radius: 50%;
      background: #FFF;
      cursor: default;
      box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #EBEBEB, 0 3px 6px -3px #BBB;

      &:after {
        content: "";
        display: block;
        position: absolute;
        height: 13px;
        width: 13px;
        background: #379b21;
        border-radius: 50%;
        left: 3px;
        top: 3px;
      }

      &-upper {

        .noUi-tooltip {

          .proximity & {
            bottom: 130%;

          }
        }
      }

    }

    &-state {

      &-tap {

        .noUi-connect,
        .noUi-origin {
          transition: transform 0.3s;
        }
      }

      &-drag {

        * {
          cursor: inherit !important;
        }
      }
    }

    &-draggable {
      cursor: ew-resize;
    }

    &-active {
      box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #DDD, 0 3px 6px -3px #BBB;
    }

    &-pips {
      position: absolute;
      color: #999;
      box-sizing: border-box;

      * {
        box-sizing: border-box;
      }

      &-horizontal {
        padding: 10px 0;
        height: 80px;
        top: 100%;
        left: 0;
        width: 100%;
      }

      &-vertical {
        padding: 0 10px;
        height: 100%;
        top: 0;
        left: 100%;
      }
    }

    &-value {
      position: absolute;
      white-space: nowrap;
      text-align: center;

      &-sub {
        color: #ccc;
        font-size: 10px;
      }

      &-horizontal {
        transform: translate(-50%, 50%);
      }

      &-vertical {
        transform: translate(0, -50%, 0);
        padding-left: 25px;
      }
    }

   &-marker {
     position: absolute;
     background: #CCC;

     &-sub,
     &-large {
       background: #AAA;
     }

     &-horizontal {

       &.noUi-marker {
         margin-left: -1px;
         width: 2px;
         height: 5px;

         &-sub {
           height: 10px;
         }

         &-large {
           height: 15px;
         }
       }
     }

     &-vertical {

       .noUi-marker {
         width: 5px;
         height: 2px;
         margin-top: -1px;

         &-sub {
           width: 10px;
         }

         &-large {
           width: 15px;
         }
       }
     }
   }

   &-rtl {

     .noUi-value-horizontal {
       transform: translate(50%, 50%);
     }

     .noUi-value-vertical {
       transform: translate(0, 50%);
     }
   }

   &-tooltip {
     display: block;
     position: absolute;
     color: #000;
     padding: 5px;
     text-align: center;
     white-space: nowrap;
   }
}

[disabled] {

  .noUi {

    &-connect {
      background: #B8B8B8;
    }

    &-target,
    &-handle,
    &-handle {
      cursor: not-allowed;
    }
  }
}





