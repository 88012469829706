//============ Mixins start =========

@mixin mobile {
    @media (max-width: $mobile) {
        @content;
    }
}

@mixin mobile-large {
    @media (max-width: $mobile-large) {
        @content;
    }
}

@mixin tablet-small {
    @media (max-width: $tablet-small) {
        @content;
    }
}

@mixin tablet {
    @media (max-width: $tablet) {
        @content;
    }
}

@mixin desktop-small {
    @media (max-width: $desktop-small) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: $tablet) {
        @content;
    }
}

//============ Mixins end =========
