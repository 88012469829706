//============ Payment style start ===========
@import "~intl-tel-input/build/css/intlTelInput.css";

.payment {

  &-method {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &-item {
      padding: 0 5px;
      display: inline-block;
    }

    &-checkbox {
      vertical-align: middle;
    }
  }

  &-choose {
    position: relative;
  }

  &-sprite {
    width: 47px;
    height: 31px;
    display: inline-block;
    background-image: url('../../img/payment-sprite.png');
    background-repeat: no-repeat;
    cursor: pointer;
  }

  &-choose {
    vertical-align: middle;
    display: inline-block;
  }

  &-affirm {
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;

    &-notify {
      position: absolute;
      padding: 5px;
      right: 0;
      background-color: #f6de97;
      cursor: pointer;
      border-radius: 5px;
    }

    img {
      vertical-align: baseline;
      padding-right: 10px;
    }
  }

  &-fieldset {
    border: none;
    outline: none;
    padding-top: 0;
  }
}

.affirm {

  &-warning {
    margin-top: 15px;
  }

  &.btn_green {
    padding: 0;
    height: 47px;
    width: 200px;
    display: inline-block;

    img {

      width: 100px;
      border-right: 1px solid #ffffff;
      margin-right: 5px;
      vertical-align: middle;
    }

    .btn-text {
      vertical-align: middle;
    }
  }
}


.wireTransferInfo-wrapper {
    padding: 20px 0;
}

.card {

  &-name {
    position: absolute;
    bottom: -13px;
    left: 0;
    font-size: 0.84em;
  }

  &-dates {
    margin: 8px 0;
  }

  &-cvv {

    .lang-es & {

      width: 55%;
    }

  }
}


.b-tooltip_input-half {
    top: 30px;
    right: 75px;

    .lang-es & {
        right: 55px;
    }
}

.b-tooltip_input {
    top: 30px;
    right: -70px;

    .lang-es & {
        right: -85px;
    }
}

.input_half {
    width: 50%;
}


.ae {
    background-position: -5px -5px;
}

.discover {
    background-position: -63px -5px;
}

.mc {
    background-position: -120px -5px;
}

.visa {
    background-position: -177px -5px;
}

.visa-e {
    background-position: -235px -5px;
}

.wt {
    width: 60px;
    height: 35px;
    background-position: -293px -5px;
}

.help-sprite {
    width: 251px;
    height: 174px;
    display: block;
    background-image: url('../../img/help-sprite.png');
    background-repeat: no-repeat;
}

.amex_cvv {
    background-position: -5px -5px;
}

.cc_phone {
    background-position: -266px -5px;
}

.visa_cvv {
    background-position: -527px -5px;
}

.credit-card-title {
    width: 100%;
}

.billing-section {

  .intl-tel-input {
    width: 100%;
    margin: 3px 0;
  }
}

.affirm-as-low-as {
  display: inline-block;
  padding-left: 20px;

  &.payment-header {
    display: inline-block;
    width: 140px;
  }
}

.lastminute {

  &-popup {
    position: fixed;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 4;
    width: 560px;
    height: 360px;
    background-color: #ffffff;
    color: #000000;
  }

  &-title {
    padding: 25px 20px;
    position: relative;
    border-bottom: 1px solid #e3e3e3;
    font-family: "Bebas", sans-serif;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
  }

  &-container {
    padding: 25px 20px;
    position: relative;

    .row {
      padding-bottom: 15px;

      &.note-error {
        padding: 5px;
      }
    }
  }

  &-label {
    padding: 0 15px;
  }

  &-price {
    color: #19365e;

    &-words {
      display: inline-block;
      color: #555555;
    }
  }

  &-button {
    background: #ffffff;
    color: #379b21;
    box-shadow: none;
    border: 2px solid #379b21;
    font-size: 12px;
    padding: 3px 20px;
    margin: 0;

    &:hover {
      background: #ffffff;
      color: #379b21;
    }
  }

  &-select {
    width: 100px;
    padding: 7px;

    &.error {
      color: #cf0b16;
      border-color: #e7222e;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.23);
    }
  }

  &-total {
    padding-top: 15px;
    border-top: 1px solid #e3e3e3;
  }

  &-close {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 20px;
    height: 20px;
    opacity: 0.3;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }

    &:before, &:after {
      position: absolute;
      left: 9px;
      content: ' ';
      height: 21px;
      width: 2px;
      background-color: #333;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
}

.input {

  &.match-error {
    border: 1px solid #ff4622;
    color: #cf0b16;
  }
}


@include tablet {

  .payment-method {
    display: block;

    &-item {
      display: block;
      padding: 0;
    }
  }

  .b-tooltip_what {
    padding: 10px;
    border-radius: 3px;
    background: #e3e3e3;

    &:before {
      position: absolute;
      left: 50%;
      top: -11px;
      margin-left: -1px;
      width: 2px;
      height: 11px;
      content: "";
      background: #e3e3e3;
    }
  }

  .b-tooltip_input,
  .b-tooltip_input-half {
    top: auto;
    right: auto !important;
  }

  .select-group-vertical {

    .custom-dropdown {
      border-radius: 3px;
      border-top: 0;
    }
  }

  .input_half {
    width: 100%;
  }

  .card-cvv {

    .lang-es & {
      width: 100%;
    }
  }

  .payment {

    &-affirm {

      &-notify {
        position: relative;
        display: block;
        width: 32px;
        margin: 5px auto;
      }
    }
  }

  .affirm {

    &-as-low-as {
      display: block;
      padding: 10px 0;

      &.payment-header {
        width: 100%;
        display: block;
        padding: 10px 0;
      }
    }

    &-warning {
      margin-top: 25px;
    }
  }
}

@include tablet-small {

    .card-name {
        position: relative;
        top: 0;
        margin-bottom: 10px;
        padding-left: 13px;
        font-size: 1.16em;
    }
}

@include mobile {

  .lastminute {

    &-popup {
      overflow: hidden;
      width: 100%;
      height: auto;
      bottom: unset;
    }

    &-title {
      padding: 10px;
    }

    &-container {
      padding: 8px 10px 0 8px;
      font-size: 12px;

      .row {
        padding-bottom: 8px;
      }
    }

    &-select {
      padding: 0;
    }

    &-total {
      padding-top: 8px;
    }

    &-button {
      width: auto;
    }

    &-close {
      top: 10px;
      right: 10px;
    }
  }
}


//============ Payment style end ===========
