//============ Popups style start ===========

.popup {
  position: fixed;
  top: 10%;
  height: 80%;
  left: 0;
  right: 0;
  z-index: 1000;
  margin: 0 auto;
  max-width: 600px;
  font-size: 12px;
  background: #fff;
  text-align: justify;

  &-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    opacity: 0.5;
    z-index: 3;
    background-color: #000000;
  }

  &-header {
    padding: 0 15px 10px 30px;
    min-height: 40px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    background: #FFFFFF;

    .no-insurance-policy {
      margin: 0 -15px 10px -30px;
      padding: 10px 30px;
      width: calc(100% + 60px);
      background: #FAEDC6;
      font-size: 10px;
      line-height: 16px;
      font-weight: 600;
    }
  }

  &-text {
    margin-bottom: 10px;

    &-terms {
      display: block;
      padding-bottom: 10px;
    }

    &-last {
      margin-bottom: 27px;
    }
  }

  &-container {
    position: relative;
    padding: 25px 30px 40px 25px;
    overflow-y: scroll;
    font-size: 11px;
    line-height: 1.2;
    background: #fff;
    height: calc(100% - 106px);

    &-outer {
      overflow: hidden;
      position: relative;
      height: 100%;
    }

    ul {
      padding: 0 0 10px 10px;
    }

    .block-title {
      padding-bottom: 10px;
    }
  }

  &-close {
    width: 14px;
    height: 14px;
    cursor: pointer;
    background: url("../../img/button-sprite.png") -203px -10px;
  }
}

.tp-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1000;
  margin: -216px 0 0 -306px;
  width: 612px;
  border-radius: 4px;
  box-shadow: 0 0 180px rgba(0, 0, 0, 0.2);
  background: #fff;

  .popup-container {
    padding-bottom: 10px;
    height: auto;
  }

  .confirm-popup {
    overflow: hidden;
  }
}

.confirm-popup {
  border-radius: 4px;
  display: block;
  align-content: center;
  text-align: center;
  overflow: unset;

  h2 {
    padding: 5px 0;
    border-bottom: 1px solid #edf1f2;
    color: #2d74b9;
    font-size: 24px;
    font-weight: 400;
    text-align: center;
  }
}

.confirm-notice-text {
  padding-left: 110px;
  padding-bottom: 23px;

  &::before {
    position: absolute;
    left: 75px;
    width: 115px;
    height: 127px;
    content: "";
    background: url("../../img/ticket-protection.png") no-repeat;
  }

  p {
    color: #122d4e;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.6;
  }

  b {
    padding: 24px 0 57px;
    display: block;
    color: #f33;
    font-size: 18px;
    font-weight: 600;
  }
}

.confirm-question-text {
  padding: 14px 0;
  border-top: 1px solid #edf1f2;
  font-size: 18px;
  font-weight: 600;
  line-height: 36px;
  text-align: center;
}

.confirm-notation {
  padding-top: 12px;
  color: #b5b5b5;
  font-size: 11px;
  font-weight: 400;
  line-height: 14px;
  text-align: center;
}

.accept {

  &.btn {
    margin: 0 34px 0 0;
    width: 190px;
    text-transform: none;
    height: 41px;
    padding: 0;
    font-size: 16px;
    color: #fff;
    line-height: 40px;
    background: #389b1e;
  }
}

.decline {
  opacity: 0.6;
  color: #333;
  font-size: 16px;
  font-weight: 600;
  text-decoration: underline;
}

.mobile-notation {
  display: none;
}

@include tablet-small {

  .popup {
    width: 90%;

    &-header {
      width: inherit;
    }

    &-container {
      padding: 15px 25px;
    }
  }
}

@include mobile {
  .tp-popup {
    top: 0;
    left: calc(50% - 160px);
    margin: 10px 0;
    height: 410px;
    width: 320px;

    .lang-es & {
      height: 465px;
    }

    .popup-container {
      padding: 10px 15px 0 15px;
      height: 100%;
    }

    .confirm-popup {
      height: inherit;

      h2 {
        padding-bottom: 10px;
        height: 70px;
        font-size: 18px;
        font-weight: 700;
      }

    }
  }

  .terms {

    &.popup-container {
      padding: 10px 15px 20px 15px;
      height: calc(100% - 140px);
    }
  }

  .confirm-notice-text {
    margin: 0;
    padding: 0;
    min-height: 180px;
    width: 290px;

    &:before {
      top: 80px;
      left: calc(50% - 57px);
      width: 79px;
      height: 87px;
      background: url("../../img/tp-mobile-popup.png") no-repeat;
    }

    b {
      padding-top: 95px;
      line-height: 30px;
      font-size: 16px;
    }

    p {
      font-size: 12px;
    }
  }

  .confirm-question-text {
    padding: 10px 0 0;
    font-size: 15px;
    font-weight: 600;
    line-height: 30px;
  }

  .confirm-buttons {
    margin-bottom: 20px;
  }

  .mobile-notation {
    padding-bottom: 15px;
    display: block;
    color: #b5b5b5;
    font-size: 11px;
    font-weight: 400;
    line-height: 14px;
    text-align: center;
  }

  .confirm-notation {
    display: none;
  }

  .accept {

    &.btn {
      margin: 0 auto 10px;
      width: 50%;
    }
  }

  .decline {
    display: inline-block;
    width: 40%;
  }
}

//============ Popups style end ===========
